<template>

  <v-sheet :color="block.value.background_color" :dark="block.value.dark">
    <v-sheet  :color="block.value.background_color"  :min-height="!block.value.noPadding ? 300 : null"
              :style="backgroundImage" >

      <v-sheet :color="block.value.overlay_color"
               style="height : 100% !important"
               :class="blockTopPaddingHeaderHandle" >
        <div class="section-content py-16">

          <!-- Slot for edit block menu-->
          <slot name="edit"></slot>

          <v-sheet  class="d-flex justify-center" color="transparent" >
            <v-sheet :class="displayHide(['sm']) ? 'pa-16' : 'pa-6'"  color="transparent" width="1200">
              <div v-if="displayHide(['sm'])">
                <h1  class="text-center font-weight-light ">{{  params.title  }} </h1>
                <h4  class="text-center mb-8 font-weight-medium mt-3"> {{ params.subtitle }} </h4>
              </div>
              <div v-else>
                <h3 :style="`color: ${wsDARK}`" class="text-center ">{{ params.title }}</h3>
                <h6  class="text-center mb-8 font-weight-medium mt-3"> {{  params.subtitle  }} </h6>
              </div>

              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <h5 class="font-weight-medium">{{ $t('HumanName') }}:</h5>
                  <v-text-field  v-model="entityData.name" dense   outlined   placeholder="Ім'я"> </v-text-field>
                  <v-row>
                    <v-col cols="6">
                      <h5 class="font-weight-medium">{{ $t('Phone') }}:</h5>
                      <wsPhoneInput v-model="entityData.phone"  />
                    </v-col>
                    <v-col cols="6">
                      <h5 class="font-weight-medium">{{$t('Email')}}:</h5>
                      <v-text-field v-model="entityData.email" dense   outlined   placeholder="Email"> </v-text-field>
                    </v-col>
                  </v-row>

                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >
                  <h5 class="font-weight-medium">{{ $t('Comment') }}:</h5>
                  <v-textarea class="noResize" height="125" v-model="entityData.comment" dense  outlined placeholder="Коментар"> </v-textarea>
                </v-col>
              </v-row>

              <v-btn :loading="LOADING"
                     outlined
                     @click="addLead"
                     :x-large="displayHide(['sm'])"
                     :large="displayShow(['sm'])"

                     class="noCaps px-8"
                     block
                      >Залишити заявку</v-btn>

            </v-sheet>
          </v-sheet>


        </div>

      </v-sheet>
    </v-sheet>
  </v-sheet>



</template>

<script>
import {mapState, mapActions} from "vuex";


// Import Swiper styles
import 'swiper/swiper.scss'; // core Swiper


export default {
  name: "avalonBlockProducts",

  props : {
    block : {
      type : Object,
      default() { return { name : "", value : { type : "default" } } }
    },
    index : {
      type : Number
    }
  },

  data() {
    return {
      entityData : {}
    }
  },
  computed: {
    ...mapState('templates', ['editorMode', 'templateVars_init', 'templateVars_current']),

    blockTopPaddingHeaderHandle() {
      if ( this.index === 0 && this.parametr('header_template') === 'avalonHeader_var_2' ) {
        return 'pt-16'
      }
      return ''
    },
    backgroundImage() {
      if ( this.block.value.background_type === 'img' && this.block.value.background_img) {
        return  `background: url('${this.block.value.background_img}');
                 background-attachment: fixed ;
                 background-size : cover;
                 position:relative`
      } else {
        return ''
      }

    },

    params() {
      return this.block.value ?  this.block.value : {}
    },

    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    },
  },
  methods: {
    ...mapActions('leads', ['ADD_BUSINESS_LEAD']),

    async addLead() {
      if ( !this.entityData.phone ) {
        return this.notify(this.$t('PhoneNumberEmpty'))
      }
      if ( !this.entityData.name ) {
        return this.notify(this.$t('NameEmpty'))
      }
      this.entityData.origin = 'contact_form'
      this.entityData.device = this.DEVICE
      let result = await this.ADD_BUSINESS_LEAD(this.entityData)
      if( !result ) { return }
      this.notify(this.$t('BusinessLeadCreated'))
      this.entityData = {}
    },

    //technical
    parametr(varName) {
      let param = '';
      if (this.editorMode) {
        param = this.templateVars_current[varName].value
      }
      if (!this.editorMode) {
        param = this.templateVars_init[varName]
      }
      return param
    },
  },

}
</script>

<style lang="scss" scoped>

</style>