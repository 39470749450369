<template>
  <v-dialog :scrollable="false" width="800" v-model="$store.state.templates.openImageLibrary">
    <v-sheet max-height="700" dark class="pa-6" >

      <div  class="d-flex justify-space-between mb-5 align-center" >
        <h3 class="font-weight-light"> Бібліотека</h3>
        <v-btn-toggle>
          <v-btn>Ваші</v-btn>
          <v-btn>Imager</v-btn>
        </v-btn-toggle>
        <v-btn icon > <v-icon>mdi-close</v-icon></v-btn>
      </div>

      <div style="overflow-y: auto; height: 500px">
        <v-row class="pt-6" >
          <v-col class="pr-1 mr-0" v-for="(img,i) in library" :key="i" cols="2">
            <v-hover v-slot="{ hover }">
              <imago
                  :src="img.thumb_small"
                  ratio="1.4"
                  style="border: 3px solid #444"
                  v-ripple >

                <v-fade-transition>

                  <div v-if="hover"
                       @click="setPicture(img.url)"
                       style="background-color: #000000aa;  cursor: pointer"
                       class="fill-height d-flex align-center justify-center">
                    Обрати
                  </div>

                </v-fade-transition>

              </imago>
            </v-hover>
          </v-col>
        </v-row>
      </div>

    </v-sheet>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import imago from "@/components/UI/imago";

export default {
  name: "ftImageLibrary",
  components : {
    imago
  },
  data() {
    return {
      library : []

    }
  },
  computed : {
    ...mapState('templates',  ['openImageLibrary','imageLibraryFile','businessAlias' ]),
  },
  methods : {
    ...mapActions('business', ['GET_BUSINESS_IMAGES_LIBRARY']),
    openLibrary(){


        this.GET_BUSINESS_IMAGES_LIBRARY(this.businessAlias).then((out)=>{
          if ( out.result) {
            this.library = out.data.images
          }
          this.loading = false

        }).catch(()=>{this.loading = false})

    },
    setPicture(url) {
      this.$store.state.templates.imageLibraryFile = url
      this.$store.state.templates.openImageLibrary = false
    }
  },
  mounted() {
    this.openLibrary()
  }
}
</script>

<style scoped>

</style>