<template>
<div>
  <div >
<!--    blue lighten-2-->
<!--    v-bind="{[button.params.size]:true}"-->
    <div width="100%" class="d-flex align-center">

      <v-sheet width="100%"  style="border-radius: 25px "  color="grey lighten-3"
                 outlined class="d-flex pa-3 mr-3">


          <v-icon class="mr-1">mdi-google-maps</v-icon>
          <gmap-autocomplete  :key="'address_' + gMapKeyAddress"  @input="resetGoogleAddress"
                              v-model="gmapAddress"
                              ref="gAddressQuick"
                              placeholder="Введіть адресу (вулиця та дом) "
                              lang="UA"
                              style="width: 100% ;  outline: none;"
                              :bounds="gmapBounds"
                              :options="{ bounds : gmapBounds,
                              strictBounds: true,
                              componentRestrictions: {
                                country : country,
                              },
                              }"

                              @place_changed="updateGooleLocation($event)" />

          <v-icon  :color="gMapAddressStreetCheck ? 'green lighten-1' : '' "
                   class="ml-1">mdi-check</v-icon>
        </v-sheet>
      <v-btn v-if="value.action"
             @click="buttonAction(value)"
             :color="params.buttonStyle.color.color"
             :text="params.buttonStyle.color.text"
             :dark="params.buttonStyle.color.dark"
             :light="!params.buttonStyle.color.dark"
             :x-small="params.buttonStyle.text.size === 'x-small' "
             :small="params.buttonStyle.text.size === 'small' "
             :large="params.buttonStyle.text.size === 'large' "
             :x-large="params.buttonStyle.text.size === 'x-large' "
             class=" px-6 "
             elevation="0"
             rounded
             :class="` font-weight-${params.buttonStyle.text.fontWeight}
                       ${!params.buttonStyle.text.caps ? 'noCaps' : ''}` " >

        {{  value.buttonText }}

      </v-btn>




    </div>


  </div>
</div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {gmapApi} from "vue2-google-maps";
export default {
    name: "avalonDeliveryFieldElement",
    props : ['value','params'],
    data() {
      return {
        address : {address : '', googleLocation : {}},
        country : 'UA',
        gmapAddress : '',
        gMapKeyAddress : 1,
        order : {
          components : [],
          string : ''
        },
        gMapAddressStreetCheck : false,
        gMapAddressStreet      : null,
        selectedCityData : null,
        gmapBounds : {}
      }
    },

    computed : {
      ...mapState('cart', ['quickDeliveryField']),
      ...mapState('templates',  ['editorMode', 'products', 'templateVars_init', 'templateVars_current','deliveryCountries','deliveryAllowCountries' ]),

      accentColor() {
        return `color: ${this.parametr('accent_color')}`
      },
      google : gmapApi,
   },
  watch:{
    quickDeliveryField : {
      handler() {
        if ( this.$store.state.cart.quickDeliveryField.complete ) {
          this.$store.state.cart.quickDeliveryField = {
            string : '',
            street_name : '',
            street_number: '',
            complete : false
          }
          this.gmapAddress = ''
          this.$refs.gAddressQuick.$el.value = ''
        }

      },
      deep : true
    },
    gmapAddress(value) {
      this.$store.state.cart.quickDeliveryField.string = value
    }
  },
  methods : {
    ...mapActions('cart', [ 'ADD_CART_ITEM','REMOVE_CART_ITEM']),

    buttonAction(button) {


      if ( this.editorMode ) { return }
      switch ( button.action ) {
        case 'gotoPage'      : this.gotoPage(button.params); break;
        case 'gotoLink'      : this.gotoLink(button.params); break;
        case 'openCart'      : this.openCart(); break;
        case 'orderProducts' : this.orderProducts(button); break;
        case 'addToCart'     : this.addToCart(button.params); break;
      }
    },
    gotoPage( value ) {
      this.notify('gotoPage : '+ value)
    },
    gotoLink( value ) {
      this.notify('gotoLink : '+ value)
    },
    openCart() {
    //  this.$store.state.cart.showCart = true
      this.$store.state.cart.showCheckout = true
    },
    orderProducts( value ) {
      this.$store.state.cart.cart = {};
      this.$store.state.cart.quantity = 0;
      this.addToCart( value )
      this.$store.state.cart.showCheckout = true
    },
    addToCart( value ) {

      value.products.forEach((id) => {
       let product =  this.products.find(el=>el.id === id)
        this.ADD_CART_ITEM(product)
      })

    },

     //new google
     resetGoogleAddress($event){
       this.gmapAddress = $event.target.value
     },
    getGmapBounds() {
      let data = this.selectedCityData
      this.$gmapApiPromiseLazy().then(() => {
        this.gmapBounds = data ? new this.google.maps.LatLngBounds(
            new this.google.maps.LatLng(data.bounds.south, data.bounds.west),
            new this.google.maps.LatLng(data.bounds.north, data.bounds.east),
        ) : {}
      })
    },
    updateGooleLocation($event){

      let streetName = $event.address_components.find( el=> el.types.includes('route') )
      let streetNumber = $event.address_components.find( el=> el.types.includes('street_number') )
      streetName = streetName ? streetName.long_name : ''
      streetNumber = streetNumber ? streetNumber.long_name : ''

      this.$store.state.cart.quickDeliveryField.fromGoogle = true
      this.$store.state.cart.quickDeliveryField.street_name = streetName
      this.$store.state.cart.quickDeliveryField.street_number = streetNumber

      this.gmapAddress = streetName + ' ' + streetNumber
      this.$refs.gAddressQuick.$el.value = streetName + ' ' + streetNumber

    }

    //technical
  },
  mounted() {

    if ( this.deliveryCountries.length === 1 ) {
      this.country = this.deliveryCountries[0].cca2
      if ( this.deliveryCountries[0].permittedCities.length > 0 ) {
        this.order.cityName = this.deliveryCountries[0].permittedCities[0].name
        this.order.cityPlaceId = this.deliveryCountries[0].permittedCities[0].placeId
        this.selectedCityData = this.deliveryCountries[0].permittedCities[0];
        this.gMapKeyAddress++;
      }
    }
    this.getGmapBounds()

  }







}
</script>

<style scoped>

</style>