<template>
  <div>
    <v-sheet>

      <div class="section-content py-16">

        <!-- Slot for edit block menu-->
        <slot name="edit"></slot>


        <div v-if="type === 'default'">
          <h1 :style="accentColor" class="">Наше найсмачніше меню</h1>
          <div class="mt-12  d-flex align-center justify-center">
            <v-btn text class="noCaps"
                   @click="selectedCategory = ''" >
              <h3  class="font-weight-medium ">Всі</h3>
            </v-btn>
            <v-btn text class="noCaps"
                   v-for="(category,i) in product_categories" :key="i"
                   @click="selectedCategory = category">
              <h3  class="font-weight-medium ">{{ category }}</h3>
            </v-btn>

          </div>
          <v-row class="pt-16 mb-8">
            <v-col v-for="(product,i) in products_filtered" :key="i" cols="6" class="px-8">
              <div>

                <div class="d-flex mb-3">
                  <v-avatar class="mr-3" style="border: 5px solid #7a7a7a"
                            size="72"  >
                    <v-img transition="xxx" :src="product.image_small" ratio="1" />
                  </v-avatar>
                  <div style="width: 100%">
                    <div class="d-flex justify-space-between align-center" >
                      <h4>{{  product.name  }} </h4>
                      <div class="mx-4" style="width: 150px" ><v-divider style="border-style: dashed" /></div>
                      <h4 :style="accentColor">{{  product.price  }} {{ BUSINESS_CURRENCY }}</h4>
                    </div>
                    {{  product.shortAbout  }}
                    <div class="d-flex align-center justify-end mt-2">
                      <div v-if="cart[product.id]" class="d-flex">
                        <v-btn class="mr-3" small  icon @click="removeFromCart(product)">
                          <v-icon small>mdi-minus</v-icon>
                        </v-btn>
                        <h3 class="mr-3">{{  cart[product.id].quantity }}</h3>
                        <v-btn small  icon @click="addToCart(product)">
                          <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                      <v-btn @click="addToCart(product)" v-else class="noCaps" :style="accentColor" small outlined >
                        Додати
                      </v-btn>
                    </div>
                  </div>
                </div>

              </div>
            </v-col>
          </v-row>
        </div>

        <div v-if="type === 'swiper' " class="d-flex justify-center align-center">
          <div style="outline: none" class="swiper-button-next" slot="button-next">
            <v-btn icon large>
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </div>
          <swiper class="swiper d-flex justify-center" style="width: 100%" :key="swiperKey"  :options="swiperOption">

            <swiper-slide   v-for="(product,i) in products_filtered" :key="i">

              <div>
                <div class="d-flex justify-center">
                  <v-avatar class="text-center" size="150">
                    <v-img transition="xxx" :src="product.image_medium" ratio="1"/>
                  </v-avatar>
                </div>
                <h3 class="text-center font-weight-medium mt-6" :style="accentColor">{{ product.name }} </h3>
                <h4 class="text-center font-weight-light">{{ product.price }} {{ BUSINESS_CURRENCY }}</h4>
                <div class="d-flex justify-center">
                  <v-btn @click="openProductDetails(product)" small text class="noCaps">Детальніше</v-btn>
                </div>
                <div class="d-flex align-center justify-center mt-2">
                  <div v-if="cart[product.id]" class="d-flex mt-3">
                    <v-btn class="mr-3" small icon @click="removeFromCart(product)">
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                    <h3 class="mr-3">{{ cart[product.id].quantity }}</h3>
                    <v-btn small icon @click="addToCart(product)">
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                  </div>

                  <v-btn @click="addToCart(product)" v-else
                         class="noCaps mt-3"
                         :color="parametr('accent_color')"
                         small dark rounded elevation="0">
                    Додати
                  </v-btn>

                </div>
              </div>

            </swiper-slide>

            <div class="swiper-pagination d-flex justify-center" slot="pagination">
            </div>
          </swiper>
          <div style=" outline: none;" class="swiper-button-prev" slot="button-prev">
            <v-btn icon large>
              <v-icon large>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>


      </div>
    </v-sheet>
    <v-dialog v-if="showDetails" v-model="showDetails" width="700">
      <v-sheet class="px-8 py-6" >
        <v-sheet class="d-flex justify-space-between align-center">
          <h3 class="text-center font-weight-medium " :style="accentColor">{{ selectedPoduct.name }} </h3>
          <v-btn small icon color="grey darken-3" @click="showDetails = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-sheet>
        <v-divider class="mb-5 mt-2" />
        <v-sheet min-height="350">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" >
              <div>
                <div class="d-flex justify-center">
                  <v-avatar class="text-center" size="150">
                    <v-img transition="xxx" :src="selectedPoduct.image_medium" ratio="1"/>
                  </v-avatar>
                </div>
                <h4 class="text-center font-weight-light">{{ selectedPoduct.price }} {{ BUSINESS_CURRENCY }}</h4>

                <div class="d-flex align-center justify-center mt-2">
                  <div v-if="cart[selectedPoduct.id]" class="d-flex mt-3">
                    <v-btn class="mr-3" small icon @click="removeFromCart(selectedPoduct)">
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                    <h3 class="mr-3">{{ cart[selectedPoduct.id].quantity }}</h3>
                    <v-btn small icon @click="addToCart(selectedPoduct)">
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                  </div>

                  <v-btn @click="addToCart(selectedPoduct)" v-else
                         class="noCaps mt-3"
                         :color="parametr('accent_color')"
                         small dark rounded elevation="0">
                    Додати
                  </v-btn>

                </div>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" >
              <h4  style="white-space: pre;"
                   class="font-weight-light text-wrap" >{{ selectedPoduct.fullAbout }}</h4>
            </v-col>
          </v-row>
        </v-sheet>
      </v-sheet>
    </v-dialog>
  </div>

</template>

<script>
import {mapState, mapActions} from "vuex";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

// Import Swiper styles
import 'swiper/swiper.scss'; // core Swiper


export default {
  name: "avalonBlockProducts",
  props : {
    block : {
      type : Object,
      default() { return { name : "", value : { type : "default" } } }
    },
    index : {
      type : Number
    }
  },

  components: {
    Swiper,
    SwiperSlide
  },

  data() {
    return {

      displayType : "default",

      selectedPoduct : {},
      showDetails : false,
      swiperKey : 0,
      selectedCategory: '',
    }
  },
  computed: {
    ...mapState('templates', ['editorMode', 'products', 'product_categories', 'templateVars_init', 'templateVars_current']),
    ...mapState('cart', ['cart']),

    type() {
      return this.block.value ?  this.block.value.type : "default"
    },

    swiperOption() {
      return {
        loop: false,
        slidesPerView : this.productsSlideAmount,
        spaceBetween : 16,
        navigation : {
          nextEl : '.swiper-button-prev',
          prevEl : '.swiper-button-next',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable : true,
        // renderBullet: (idx, className) =>
        //     `<v-btn small href="#" class="noCaps ${className}">
        //     ${idx}
        //     </v-btn>`
        }
      }
    },
    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    },
    productsSlideAmount() {
      switch (  this.$vuetify.breakpoint.name ) {
        case 'xs' : return 2;
        case 'sm' : return 3;
        default : return 4
      }
    },
    products_filtered() {
      var products = this.products

      if (this.selectedCategory) {
        products = products.filter((el) => el.category === this.selectedCategory)
      }

      return products


    }
  },
  methods: {
    ...mapActions('cart', ['ADD_CART_ITEM', 'REMOVE_CART_ITEM']),
    parametr(varName) {
      let param = '';
      if (this.editorMode) {
        param = this.templateVars_current[varName].value
      }
      if (!this.editorMode) {
        param = this.templateVars_init[varName]
      }
      return param
    },
    addToCart(product) {
      // this.$store.state.cart.cart = product
      this.ADD_CART_ITEM(product)
      this.notify(`${product.name} додано до корзини`)
      // this.$forceUpdate()
    },
    removeFromCart(product) {
      this.REMOVE_CART_ITEM(product)
      this.notify(`${product.name} видалено із корзини`)
    },
    openProductDetails(product) {
      this.selectedPoduct = product
      this.showDetails = true
    }
  },
  watch: {
    products_filtered() {
      this.swiperKey++;
    },
    productsSlideAmount(){
      this.swiperKey++
    }
  },
}
</script>

<style lang="scss" scoped>

</style>