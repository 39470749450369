<template>
  <div  color="grey darken-3" width="100%"
        @mouseenter="hover(true)"
        @mouseleave="hover(false)"  >



      <v-btn-toggle   v-model="toggle_none"  >

        <slot name="prepend"></slot>

        <v-menu  v-for="(param,i) in paramSet" :key="i"
                 v-model="expand[i]"  offset-y
                 :close-on-content-click="!param.stayOnClick"  >

          <template v-slot:activator="{ on, attrs }">

            <v-btn color="grey darken-3" x-large class="noCaps caption text-left" v-bind="attrs" v-on="on">

              <v-icon v-if="param.iconAlign === 'left'" size="30"  class="mr-1">{{  param.icon  }}</v-icon>

              <span class="font-weight-light">{{  param.text  }}  <br>
              <span class="mt-2 font-weight-medium">


                <span v-if="param.type === 'display'  ">
                  <span v-for="(p,i) in paramsObject[param.name]" :key="p">
                    <b>{{ p }}</b>{{ i !== (paramsObject[param.name].length - 1) ? ',' : '' }}
                  </span>
                </span>

                <span v-if="!['margin','display','imageParams'].includes(param.type)  ">
                  {{  getValueName( param.name )  }}
                </span>

              </span>
            </span>

              <v-icon v-if="param.iconAlign === 'right'"
                      :color="param.iconColor === 'self' ?  paramsObject[param.name] : '' "
                      size="30"  class="ml-1">
                {{  param.icon  }}
              </v-icon>

            </v-btn>

          </template>

          <v-sheet v-if="param.type === 'select' " dark class="py-5 ">

            <div   v-for="(item,j) in param.values" :key="j"
                   @click="setValue(param.name,item.value)"
                   @mouseenter="hover(true)"
                   @mouseleave="hover(false)"
                   class="headerMenuItem px-4 py-1 "
                   :class="item.value === paramsObject[param.name] ? 'active' : ''"
                   v-ripple >
              {{ item.text }}
            </div>

          </v-sheet>

          <ftColorPicker v-if="param.type === 'colorPicker' "
                         v-model="paramsObject[param.name]"
                         @collapse="expandMenu(i,false)"
                         @hover="hover"
                         :content="true" />

          <ftMarginEdit  v-if="param.type === 'margin' "
                         v-model="paramsObject[param.name]"
                         @collapse="expandMenu(i,false)"
                         @hover="hover" />

          <ftDisplayEdit  v-if="param.type === 'display' "
                          v-model="paramsObject[param.name]"
                          @collapse="expandMenu(i,false)"
                          @hover="hover" />
          <ftImageParamsEdit  v-if="param.type === 'imageParams' "
                              v-model="paramsObject[param.name]"
                              @collapse="expandMenu(i,false)"
                              @hover="hover" />



        </v-menu>

        <slot name="append"></slot>

        <!-- DELETE BUTTON-->
        <v-btn  height="52" width="10" v-if="showDelete" color="red darken-2 " class="px-0" @click="$emit('delete')">
          <v-icon color="grey lighten-3" >mdi-delete</v-icon>
          <span> <br></span>
        </v-btn>

      </v-btn-toggle>


  </div>
</template>

<script>
import ftColorPicker from "@/components/templates/editor/UI/ftColorPicker";
import ftMarginEdit from "@/components/templates/editor/UI/ftMarginEdit";
import ftDisplayEdit from "@/components/templates/editor/UI/ftDisplayEdit";
import ftImageParamsEdit from "@/components/templates/editor/UI/ftImageParamsEdit";
export default {
  name: "ftParamsBar",
  props : ['value','input','round','autoInput'],
  components : {
    ftColorPicker,
    ftMarginEdit,
    ftDisplayEdit,
    ftImageParamsEdit,

  },
  data() {
    return {
      showDelete : false,
      toggle_none : null,
      paramSet : [],
      paramsObject : { },
      valueNamesObject : {},
      items : [],
      objectReady : false,
      expand : [],
    }
  },
  computed : {
    defaultValues() { return {
      fontWeight : {
        name : 'fontWeight',
        icon : 'mdi-format-text',
        iconAlign : 'left',
        text : 'Товщина',
        default: 'light',
        type : 'select',
        values : [
          { text : "Тонкий", value : 'thin'       },
          { text : "Легкий", value : 'light'      },
          { text : "Звичайний", value : 'regular' },
          { text : "Середній", value : 'medium'   },
          { text : "Жирний", value : 'bold'       },
          { text : "Чорний", value : 'black'      }
        ]
      },
      headingSize : {
        name : 'headingSize',
        icon : 'mdi-format-header-1',
        iconAlign : 'left',
        text : 'Розмір',
        default: 'h3',
        type : 'select',
        values : [
          { text : 'H1', value : 'h1' },
          { text : 'H2', value : 'h2' },
          { text : 'H3', value : 'h3' },
          { text : 'H4', value : 'h4' },
          { text : 'H5', value : 'h5' },
          { text : 'H6', value : 'h6' }
        ]
      },
      fontSize : {
        name : 'fontSize',
        icon : 'mdi-format-header-1',
        iconAlign : 'left',
        text : 'Розмір',
        default: 'h3',
        type : 'select',
        values : [
          { text : 'H1', value : '10' },
          { text : 'H2', value : '12' },
          { text : 'H3', value : '14' },
          { text : 'H4', value : '16' },
          { text : 'H5', value : '18' },
          { text : 'H6', value : '20' },
          { text : 'H6', value : '22' },
          { text : 'H6', value : '24' },
          { text : 'H6', value : '26' },
          { text : 'H6', value : '28' },
          { text : 'H6', value : '30' },
        ]
      },
      align : {
        name : 'align',
        icon : 'mdi-format-horizontal-align-center',
        iconAlign : 'left',
        text : 'Align',
        default: 'left',
        type : 'select',
        values : [
          { text : 'Зліва', value : 'left' },
          { text : 'Центр', value : 'center' },
          { text : 'Зправа', value : 'right' },
        ]
      },
      color : {
        name : 'color',
        icon : 'mdi-square',
        iconAlign : 'right',
        iconColor : 'self',
        text : 'Колір',
        default: this.TEMPLATES_ACCENT_COLOR(),
        type : 'colorPicker',
        stayOnClick : true,
      },
      margin : {
        name : 'margin',
        icon : 'mdi-arrow-all',
        iconAlign : 'left',
        text : 'Відступи',
        default: { mb: '3' },
        type : 'margin',
        stayOnClick : true,
      },
      display : {
        name : 'display',
        icon : 'mdi-magnify',
        iconAlign : 'left',
        text : 'Видимість',
        default: ['sm','md','lg'],
        type : 'display',
        stayOnClick : true,
      },
      imageParams : {
        name : 'imageParams',
        icon : 'mdi-image',
        iconAlign : 'left',
        text : 'Параметри',
        default: {},
        type : 'imageParams',
        stayOnClick : true,
      },
      lineHeight : {
        name : 'lineHeight',
        icon : 'mdi-format-line-spacing',
        iconAlign : 'left',
        text : 'Cтрока',
        default: 'light',
        type : 'select',
        values : [
          { text : '1'  , value : '1' },
          { text : '1.1', value : '1.1' },
          { text : '1.2', value : '1.2' },
          { text : '1.3', value : '1.3' },
          { text : '1.4', value : '1.4' },
          { text : '1.5', value : '1.5' },
          { text : '1.6', value : '1.6' },
          { text : '1.7', value : '1.7' },
          { text : '1.8', value : '1.8' },
          { text : '1.9', value : '1.9' },
          { text : '2'  , value : '2'   },
        ]
      },
      dark : {
        name: 'dark',
        icon : 'mdi-darkness-6',
        iconAlign : 'left',
        text : 'Режим',
        default: true,
        type : 'select',
        values : [
          { text : "Темний" ,    value : true   },
          { text : "Світлий" ,   value : false },
        ]
      },
      size : {
        name: 'size',
        icon : 'mdi-resize',
        iconAlign : 'left',
        text : 'Розмір',
        default: null,
        type : 'select',
        values : [
          { text : "Дуже маленький", value: "x-small" },
          { text : "Маленький", value: "small" },
          { text : "Нормальний", value: null },
          { text : "Великий", value: "large" },
          { text : "Дуже великий", value: "x-large" },
        ]
      },


      // Button
      caps : {
        name: 'caps',
        icon : 'mdi-alphabetical',
        iconAlign : 'left',
        text : 'Літери',
        default: false,
        type : 'select',
        values : [
          { text : "Великі" ,    value : true   },
          { text : "Нормальні" , value : false },
        ]
      },
      text :  {
        name: 'text',
        icon : 'mdi-card',
        iconAlign : 'left',
        text : 'Фон',
        default: false,
        type : 'select',
        values : [

          { text : "Колір" ,  value : false  },
          { text : "Не має" , value : true  },
        ]
      },
    }
    }
  },
  watch : {
    paramsObject: {
      handler(value){
       if ( this.objectReady ) { this.$emit('input',value ) }
      },
      deep: true
    }
  },
  methods : {

    setValue(name,value) {
      this.paramsObject[name] = value
      this.paramsObject = Object.assign({},this.paramsObject)
    },
    getValueName(name) {

    return this.valueNamesObject[name][this.paramsObject[name]] ?
           this.valueNamesObject[name][this.paramsObject[name]] :
           this.paramsObject[name]

    },

    //technical
    expandMenu(i,value) {
      this.$set(this.expand,i,value)
    },
    hover(val) {
      this.$emit('hover',val)
    },
  },
  beforeMount() {

    let object = this.value ? this.value : {}

    if ( this.input ) { this.paramSet = this.input }
    if ( this.autoInput ) {

      this.autoInput.forEach( (paramName) => {
        if ( paramName !== 'delete' ) {
          let param = this.defaultValues[paramName]
          this.defaultValues[paramName].name = paramName
          this.paramSet.push(param)
        } else {
          this.showDelete = true
        }

      })

    }

    this.paramSet.forEach((value) => {
        this.valueNamesObject[value.name] = { }
        if ( !object[value.name] ) { object[value.name] = value.default }
        if ( value.values ) {
          value.values.forEach((paramvalue) => {
            this.valueNamesObject[value.name][paramvalue.value] = paramvalue.text
          })
        }
    })

    this.paramsObject = Object.assign({},object)
    this.objectReady = true

  }
}
</script>

<style scoped>
.headerMenuItem {
  font-size: 0.9rem !important;
  transition: all 0.2s ease;
  text-decoration: none;
  color : #f1f1f1;
  cursor : pointer;
}
.headerMenuItem:hover {
  background-color: #343434 ;
}
.active {
  background-color: #343434;
}
</style>