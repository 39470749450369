<template>

    <v-sheet :color="block.value.background_color" :dark="block.value.dark">
      <v-sheet  :color="block.value.background_color"  :min-height="!block.value.noPadding ? 300 : null"
                :style="backgroundImage" >

        <v-sheet :color="block.value.overlay_color"
                 style="height : 100% !important"
                 :class="blockTopPaddingHeaderHandle" >

          <div class="section-content py-16">

            <!-- Slot for edit block menu-->
            <slot name="edit"></slot>

            <v-sheet color="transparent" class="d-flex justify-center mb-6">
              <div>
                <h1 style="line-height: 1.2"  class="font-weight-light text-center">{{ block.value.title }}</h1>
                <h3 style="line-height: 1.2"  class="font-weight-light text-center">{{ block.value.subtitle }}</h3>
              </div>
            </v-sheet>

            <v-data-table v-if="!SM"
                          style="background-color: transparent"
                          hide-default-footer
                          :dark="block.value.dark"
                          disable-pagination
                          :items="itemsFiltered" >



              <template v-slot:item="{ item }">

                <tr style="background: transparent" class="pointer"  >
                  <td class="px-0 pl-3 py-3" width="1">
                    <router-link :to="businessPublicLink('course/' + item.uuid  )">
                      <v-img style="border : 2px solid white" v-if="item.img" class="wsRoundedHalf" height="150" width="240"  :src="item.img" ></v-img>
                      <v-img style="border : 2px solid white" v-else class="wsRoundedHalf" height="150" width="240"  src="@/assets/img/course_blank.jpg" ></v-img>
                    </router-link>
                  </td>
                  <td valign="top" class="py-3">
                    <router-link class="noUnderline  text--darken-3"
                                 :class="block.value.dark ? 'white--text' : 'grey--text'"
                                 :to="businessPublicLink('course/' + item.uuid  )">
                      <h4>{{ item.name }}</h4>
                      <h4 class="font-weight-light mt-3">
                        {{ item.short_description }}
                      </h4>
                    </router-link>
                  </td>
                  <td valign="top" class="py-3" align="right"  width="150px">
                    <div v-if="item.payed">
                      <div v-if="item.has_payment_plans">

                        <div v-if="item.discount">
                          <h4> {{ $t('priceFrom') }} {{ item.min_price - Math.round(item.discount/100*item.min_price) }}
                            {{ BUSINESS_CURRENCY }} </h4>
                          <h5 style="text-decoration: line-through" > {{ $t('priceFrom') }} {{ item.min_price }} {{ BUSINESS_CURRENCY }}  </h5>
                          <h5 class="font-weight-bold">  {{ $t('Discount')}} : {{ item.discount }} % </h5>
                        </div>
                        <h4 v-else> {{ $t('priceFrom') }}  {{ item.min_price }} {{ BUSINESS_CURRENCY }}</h4>

                      </div>
                      <div v-else>

                        <div v-if="item.discount">
                          <h4> {{ item.price - Math.round(item.discount/100*item.price) }} {{ BUSINESS_CURRENCY }} </h4>
                          <h5 style="text-decoration: line-through" > {{ item.price }} {{ BUSINESS_CURRENCY }}  </h5>
                          <h5 class="font-weight-bold">  {{ $t('Discount')}} : {{ item.discount }} % </h5>
                        </div>
                        <h4 v-else>{{ item.price }} {{ BUSINESS_CURRENCY }}</h4>
                      </div>


                    </div>
                    <h4 v-else > </h4>

                  </td>
                </tr>
              </template>


            </v-data-table>
            <v-data-iterator v-else
                          style="background-color: transparent"
                          hide-default-footer
                          disable-pagination
                          :dark="block.value.dark"
                          :items="items" >

              <template v-slot:default="{ items }">
                <v-sheet v-for="(item,i) in items" :key="i" class="px-3">
                  <router-link :to="businessPublicLink('course/' + item.uuid  )">
                    <v-img style="border : 2px solid white" v-if="item.img" class="wsRoundedHalf"  :src="item.img" ></v-img>
                    <v-img style="border : 2px solid white" v-else class="wsRoundedHalf"  src="@/assets/img/course_blank.jpg" ></v-img>
                  </router-link>

                  <h4 class="mt-3 text-center"> {{ item.name }}</h4>
                  <v-btn :to="businessPublicLink('course/' + item.uuid  )"
                         outlined block class="noCaps my-3 d-flex align-center">
                    {{ $t('Details') }}
                    <v-icon small>mdi-chevron-right</v-icon>
                  </v-btn>

                </v-sheet>
              </template>

            </v-data-iterator>

          </div>

        </v-sheet>
      </v-sheet>
    </v-sheet>



</template>

<script>
import {mapState, mapActions} from "vuex";


// Import Swiper styles
import 'swiper/swiper.scss'; // core Swiper


export default {
  name: "avalonBlockProducts",
  props : ['block','index'],
  data() {
    return {
      items : [],
      selectedPoduct : {},
      showDetails : false,
      swiperKey : 0,
      selectedCategory: '',
    }
  },
  computed: {
    ...mapState('templates', ['editorMode', 'products', 'product_categories', 'templateVars_init', 'templateVars_current']),
    ...mapState('cart', ['cart']),


    itemsFiltered() {
      let items = this.items
      if ( items.length === 0 ) {
        return []
      }

      if ( this.block && this.block.value && this.block.value.course_category) {
        items = items.filter( el=> el.category_id === this.block.value.course_category )
      }

      return items
    },


    blockTopPaddingHeaderHandle() {
      if ( this.index === 0 && this.parametr('header_template') === 'avalonHeader_var_2' ) {
        return 'pt-16'
      }
      return ''
    },
    backgroundImage() {
      if ( this.block.value.background_type === 'img' && this.block.value.background_img) {
        return  `background: url('${this.block.value.background_img}');
                 background-attachment: fixed ;
                 background-size : cover;
                 position:relative`
      } else {
        return ''
      }

    },

    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    },
    productsSlideAmount() {
      switch (  this.$vuetify.breakpoint.name ) {
        case 'xs' : return 2;
        case 'sm' : return 3;
        default : return 4
      }
    },
    products_filtered() {
      var products = this.products

      if (this.selectedCategory) {
        products = products.filter((el) => el.category === this.selectedCategory)
      }

      return products


    }
  },
  methods: {
    ...mapActions('cart', ['ADD_CART_ITEM', 'REMOVE_CART_ITEM']),
    ...mapActions('courses', ['GET_BUSINESS_COURSES']),

    parametr(varName) {
      let param = '';
      if (this.editorMode) {
        param = this.templateVars_current[varName].value
      }
      if (!this.editorMode) {
        param = this.templateVars_init[varName]
      }
      return param
    },
    addToCart(product) {
      // this.$store.state.cart.cart = product
      this.ADD_CART_ITEM(product)
      this.notify(`${product.name} додано до корзини`)
      // this.$forceUpdate()
    },
    removeFromCart(product) {
      this.REMOVE_CART_ITEM(product)
      this.notify(`${product.name} видалено із корзини`)
    },
    openProductDetails(product) {
      this.selectedPoduct = product
      this.showDetails = true
    }
  },
  watch: {
    products_filtered() {
      this.swiperKey++;
    },
    productsSlideAmount(){
      this.swiperKey++
    }
  },
  async mounted() {
    let result = await this.GET_BUSINESS_COURSES();
    if ( !result ) {
      return  }
    this.items = result !== true ? result.courses : [];
  }
}
</script>

<style lang="scss" scoped>

</style>