<template>
  <v-sheet :color="block.value.background_color" :dark="block.value.dark">
    <v-sheet  :color="block.value.background_color"  :min-height="!block.value.noPadding ? 300 : null"
              :style="backgroundImage" >

      <v-sheet :color="block.value.overlay_color"
               style="height : 100% !important"
               :class="blockTopPaddingHeaderHandle" >
        <div class="section-content py-16">

          <!-- Slot for edit block menu-->
          <slot name="edit"> </slot>

          <v-sheet color="transparent" class="d-flex justify-center  ">
            <div>
              <h1 style="line-height: 1.2"  class="font-weight-light text-center">{{ block.value.title }}</h1>
              <h3 style="line-height: 1.2"  class="font-weight-light  text-center">{{ block.value.subtitle }}</h3>
            </div>

          </v-sheet>
          <v-sheet color="transparent" class="d-flex justify-center px-9  pt-6" >
            <v-data-iterator style="width: 100%" :items="news"
                             hide-default-footer
                             disable-pagination
                             items-per-page="6">
              <template v-slot:default="{ items }">
                <v-row class="pa-0">
                  <v-col v-for="(item,i) in items" :key="i"
                         cols="12" xs="12" sm="12" md="6" lg="4" xl="4"
                         class="">
                    <router-link class="noUnderline"    :class="block.value.dark ? 'white--text' : 'black--text'" :to="businessPublicLink('news/' + item.alias)">
                      <v-sheet  class="wsRoundedHalf  " >
                        <v-img style="border : 2px solid white" v-if="item.img" class="wsRoundedHalf" aspect-ratio="1.5"  :src="item.img" ></v-img>
                        <v-img style="border : 2px solid white" v-else class="wsRoundedHalf" aspect-ratio="1.5"  src="@/assets/img/course_blank.jpg" ></v-img>
                      </v-sheet>
                      <h5 class="text-center font-weight-medium mt-3">{{ item.title }}</h5>
                      <h6 class="text-center font-weight-light ">{{ MONTH_DAY_TIME(item.date) }}</h6>
                    </router-link>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>

          </v-sheet>

        </div>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script>

import { mapActions,mapState} from "vuex";
export default {
  name: "avalonBlockNews",
  props : ['block','index'],
  data() {
    return {
      news : []
    }
  },

  computed : {
    ...mapState('templates',  [ 'businessAlias', 'editorMode', 'templateVars_init', 'templateVars_current'  ]),

    blockTopPaddingHeaderHandle() {
      if ( this.index === 0 && this.parametr('header_template') === 'avalonHeader_var_2' ) {
        return 'pt-16'
      }
      return ''
    },
    backgroundImage() {
      if ( this.block.value.background_type === 'img' && this.block.value.background_img) {
        return  `background: url('${this.block.value.background_img}');
                 background-attachment: fixed ;
                 background-size : cover;
                 position:relative`
      } else {
        return ''
      }

    },

    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    }
  },
  methods : {
    ...mapActions('news', [ 'GET_NEWS_PUBLIC']),
    parametr(varName) {
      let param = '';
      if (  this.editorMode ) { param = this.templateVars_current[varName].value }
      if ( !this.editorMode ) { param = this.templateVars_init[varName]    }
      return param
    },
  },
  async mounted() {
    this.news = await this.GET_NEWS_PUBLIC()
  }
}
</script>

<style scoped>

</style>