<template>
  <v-sheet class="pa-16">
    <v-btn  @click="addBlock" dark x-large class="noCaps" rounded :color="wsDARK">
      <v-icon>mdi-plus</v-icon>
      Додати блок
    </v-btn>

  </v-sheet>
</template>

<script>
export default {
  name: "ftEditorFooterAddBlock",
  methods : {
    addBlock () {
      this.$store.state.templates.displayEditBlock = true
      this.$store.state.templates.openEditorBlockType = 'new'
    }
  }
}
</script>

<style scoped>

</style>