<template>

  <v-menu  v-if="!content" ref="colorPicker"
           :close-on-content-click="false"
           :close-on-click="true"  left >

    <template v-slot:activator="{ on, attrs }">

      <v-btn small outlined    class="noCaps"
             v-bind="attrs" v-on="on" >
        <h4>{{ value }}</h4>
        <v-icon :color="value" class="ml-2"  >mdi-square</v-icon>
      </v-btn>


    </template>
    <v-sheet dark class="pa-3 px-4"  >

      <div class="d-flex justify-space-between mt-3 mb-3">
        <v-btn @click="value = TEMPLATES_ACCENT_COLOR() "
               :color="TEMPLATES_ACCENT_COLOR()"
               class="noCaps"
               small filled rounded  >
          Оснвний
        </v-btn>


        <v-btn plain icon @click="$refs.colorPicker.save()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-color-picker v-model="value"
                      hide-mode-switch
                      swatches-max-height="120px"
                      :mode="`hexa`"

                      show-swatches  />

    </v-sheet>

  </v-menu>
  <v-sheet v-else dark class="pa-3 px-4"
           @mouseenter="hoverOn"
           @mouseleave="hoverOut"
            >

    <div class="d-flex justify-space-between mt-3 mb-3">
      <v-btn @click="value = TEMPLATES_ACCENT_COLOR() "
             :color="TEMPLATES_ACCENT_COLOR()"
             class="noCaps"
             small filled rounded  >
        Оснвний
      </v-btn>


      <v-btn plain icon @click="$emit('collapse')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-color-picker v-model="value"
                    hide-mode-switch
                    swatches-max-height="120px"
                    :mode="`hexa`"

                    show-swatches  />

  </v-sheet>

</template>

<script>
export default {
  name: "ftColorPicker",
  props : {
    value : {
      type : String,
      default : '#333333FF'
    },
    content : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      swatches : true
    }
  },
  watch : {
    value() {
      if ( this.value.length  === 7 ) { this.value += 'FF'  }
      this.$emit('input',this.value)
    }
  },
  methods : {
    hoverOn() {
      this.$emit('hover',true)
    },
    hoverOut(){
      this.$emit('hover',false)
    },
  }
}
</script>

<style scoped>

</style>