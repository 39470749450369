<template>
  <div>

    <v-fade-transition mode="out-in">
      <div v-if="!loading">
            <avalonPagesSingle
                v-if="businessTemplate === 'avalon'"
                key="Avalon"
                :alias="alias"
                :page="$store.state.templates.page"/>
            <ftEditorFooterAddBlock v-if="editorMode" />
            <ftImageLibrary v-if="openImageLibrary" />
      </div>
      <loadingTemplate v-if="loading" />
    </v-fade-transition>

  </div>
</template>

<script>
import avalonPagesSingle from "@/components/templates/Avalon/UI/avalonPagesSingle";
import loadingTemplate from "@/components/templates/loadingTemplate";
import ftEditorFooterAddBlock from "@/components/templates/editor/ftEditorFooterAddBlock";
import ftImageLibrary from "@/components/templates/editor/UI/ftImageLibrary";

import { mapState , mapActions } from "vuex";
export default {
  name: "TemplateLoaderPagesSingle",
  components : {
    avalonPagesSingle,
    loadingTemplate,
    ftEditorFooterAddBlock,
    ftImageLibrary
  },
  props : [ 'alias' , 'pageAlias' ],
  data() {
    return {
      loading : true,
      page : null,
    }
  },
  computed : {
    ...mapState( 'templates' , ['businessAlias', 'selectedPage', 'businessTemplate', 'editorMode','openImageLibrary']) ,
  },
  watch : {
    $route(to, from ){

      if ( from === to ) {
        return
      }
      if ( to.params ) {
        if( to.params.pageAlias) {

          this.loadPage(to.params.pageAlias)
        } else {
          if( to.params.alias || to.path === '/'  ) {

            this.loadPage('homepage')
          }
        }
      }
    }
  },
  methods : {
    ...mapActions( 'business' , [ 'GET_PAGE_PUBLIC' ] ) ,
    loadPage(alias) {
      this.loading = true;
      let business = this.$route.params ? this.$route.params.alias : '';
      let data =
          {
            business :  business,
            page : alias
          }
      if ( this.$store.state.domain ) {
        data.domain = location.host
      }
      this.GET_PAGE_PUBLIC(data).then((out)=>{
        if ( out.result ) {
          this.page = out.data
          this.$store.state.templates.page        = out.data
          this.$store.state.templates.page_buffer = out.data
          this.$store.state.templates.selectedPage= out.data.alias
          window.scrollTo(0, 0)
        }
        this.loading = false
      }).catch(()=>{
        this.loading = false} )
    }
  },
  beforeMount() {
    window.scrollTo(0, 0)
    this.loadPage(this.pageAlias !== undefined ? this.pageAlias : 'homepage')
  }

}
</script>

<style scoped>

</style>