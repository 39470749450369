<template>
  <div  >

      <div v-if="page">
        <div v-for="(block,i) in page.blocks" :key="i">
          <div v-if="getComponent(block.name)">

            <component :is="getComponent(block.name)" :index="i" :block="block" :ref="getRef(block.anchor,i)" >

              <!-- EDIT BUTTON-->
              <template v-if="editorMode" #edit>
                <div :class="i === 0 ? 'mt-8' : ''"
                     class="d-flex justify-end">

                  <v-btn @click="blockEditAction( 'editBlock', block.id, i )"
                         :color="wsDARKER" class="noCaps" large rounded
                         dark elevation="0" >
                    <v-icon small class="mr-1" >mdi-pencil</v-icon>
                    {{ $t('EditBlock') }}
                  </v-btn>

                </div>
              </template>


            </component>
          </div>
        </div>
        <v-sheet dark class="pa-16 mb-16" v-if="page.blocks.length === 0">
        </v-sheet>
      </div>
      <div v-else >
        <v-sheet  v-if="!page"
                 class="d-flex align-center justify-center"
                 color="blue lighten-2"
                 width="100%" height="1000"  dark >
          <h1  class="font-weight-thin text-h3 my-16">Помилка 404: Сторінку не знайдено</h1>
        </v-sheet>
      </div>



    <newUserDialog />


  </div>
</template>

<script>
import avalonBlockProducts from "@/components/templates/Avalon/UI/blocks/avalonBlockProducts";
import avalonBlockNews from "@/components/templates/Avalon/UI/blocks/avalonBlockNews";
import avalonBlockContainer from "@/components/templates/Avalon/UI/blocks/avalonBlockContainer";
import avalonBlockCourses from "@/components/templates/Avalon/UI/blocks/avalonBlockCourses";
import avalonBlockLeads from "@/components/templates/Avalon/UI/blocks/avalonBlockLeads";
import {mapState} from "vuex";

import newUserDialog from "@/components/UI/newUserDialog";

export default {
  name: "avalonPagesSingle",
  props : ['page'],
  components : {
    avalonBlockProducts,
    avalonBlockNews,
    avalonBlockContainer,
    avalonBlockCourses,
    avalonBlockLeads,
    newUserDialog
  },
  data() {
    return {
      blockEditActions : [
        { text : 'Редагувати Блок'  , action : 'editBlock' ,   block : null,},
        { text : 'Додати Елемент'   , action : 'addElement' ,   block : 'container' },
        { text : 'Видалити Блок'    , action : 'deleteBlock' ,   block : null, },
      ]
    }
  },
  computed : {
    ...mapState('templates',  [  'editorMode','locations','triggerScroll'] )
  },
  watch : {
    triggerScroll() {
      if ( this.$route.hash ) {
        this.scrollTo(this.$route.hash)
      }
    },
    $route(to) {
      if ( to.hash ) {
        this.scrollTo(to.hash)
      }

    },
  },
  methods : {
    scrollTo(target) {

      if (!target) { return
      //  target = 'main'
      } else {
        target =  target.substring(1)
      }
      console.log("this.$refs[target]")
      console.log(this.$refs[target])
      if ( this.$refs[target] === undefined ) { return }
      this.$vuetify.goTo(this.$refs[target][0],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
          })
    },
    getRef(anchor,i) {

      if ( i === 0 && this.page.blocks.length <2 ) { return 'main' }

      return anchor ? anchor : null


    },
    getComponent(name) {
      return "avalonBlock" +  name[0].toUpperCase() + name.slice(1)
    },
    getBlockActions(block) {

      let blockEditActions = this.blockEditActions

      blockEditActions = blockEditActions.filter( el => !el.block || el.block === block )

      return blockEditActions
    },
    blockEditAction(action,index,arrayIndex) {

      if (action !== 'addElement') {
        this.$store.state.templates.selectedBlockTypeId = index
        this.$store.state.templates.selectedBlock = arrayIndex
      }

      switch (action) {
        case 'addElement' : this.addContainerElement(index);  break;
        case 'editBlock'   : this.editBlock(index); break;
        case 'deleteBlock' : this.deleteBlock(index); break;
      }
    },
    editBlock() {
      this.$store.state.templates.displayEditBlock = true
      this.$store.state.templates.openEditorBlockType = 'edit'
    },
    deleteBlock() {
      this.$store.state.templates.displayEditBlock = true
      this.$store.state.templates.openEditorBlockType = 'delete'
    },
    addContainerElement(index) {

      let arrayIndex = this.$store.state.templates.page.blocks.findIndex ( el=> el.id === index)
      if ( arrayIndex !== -1) {
        this.$store.state.templates.page.blocks[arrayIndex].value.items[1].push({
          type : "ftText",
          value : ""
        })
      }


    },
  },
  mounted() {
    if ( location.hash) {
      this.scrollTo(location.hash)
    }
  }
}
</script>

<style scoped>

</style>