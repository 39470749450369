<template>
  <v-sheet  dark class="pa-4"
            @mouseenter="hover(true)"
            @mouseleave="hover(false)" >



    <div class="d-flex justify-space-between align-center ">
     <div class="caption">Параметри</div>
      <v-btn small icon @click="$emit('collapse')">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider class="mb-2 mt-1" />

    <v-simple-table dense>
      <tbody>
        <tr>
          <td> Зверху </td>
          <td><v-btn  @click="divideValue('mt')" icon x-small>
            <v-icon x-small>mdi-minus</v-icon>
          </v-btn>
            {{ margin.mt }}
            <v-btn @click="addValue('mt')" icon x-small>
              <v-icon x-small>mdi-plus</v-icon>
            </v-btn>
          </td>
        </tr>
        <tr>
          <td> Знизу </td>
          <td><v-btn @click="divideValue('mb')"  icon x-small>
            <v-icon x-small>mdi-minus</v-icon>
          </v-btn>
            {{ margin.mb }}
            <v-btn @click="addValue('mb')" icon x-small>
              <v-icon x-small>mdi-plus</v-icon>
            </v-btn>
          </td>
        </tr>
        <tr>
          <td> Зліва </td>
          <td><v-btn @click="divideValue('ml')"  icon x-small>
            <v-icon x-small>mdi-minus</v-icon>
          </v-btn>
            {{ margin.ml }}
            <v-btn @click="addValue('ml')" icon x-small>
              <v-icon x-small>mdi-plus</v-icon>
            </v-btn>
          </td>
        </tr>
        <tr>
          <td> Справа </td>
          <td><v-btn  @click="divideValue('mr')" icon x-small>
            <v-icon x-small>mdi-minus</v-icon>
          </v-btn>
            {{ margin.mr }}
            <v-btn @click="addValue('mr')" icon x-small>
              <v-icon x-small>mdi-plus</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>


  </v-sheet>
</template>

<script>
export default {
  name: "ftMarginEdit",
  props : ['value'],
  data() {
    return {
      margin : {
        mt : 0,
        mb : 0,
        mr : 0,
        ml : 0,
      }
    }
  },
  watch : {
    margin : {
      handler(val) {
        this.$emit('input',val)
      },
      deep : true
    }
  },
  methods : {
    hover(val) {
      this.$emit('hover',val)
    },
    addValue(key) {
      if ( this.margin[key] <16) { this.margin[key]++ }
    },
    divideValue(key) {
      if ( this.margin[key] >-16) { this.margin[key]-- }
    },
  },
  mounted() {
    if(this.value) {

      for (const [key, value] of Object.entries(this.value)) {
        this.margin[key] = value
      }


    }
  }
}
</script>

<style scoped>

</style>