<template>
  <v-sheet  dark class="pa-4"
            @mouseenter="hover(true)"
            @mouseleave="hover(false)" >



    <div class="d-flex justify-space-between align-center ">
     <div class="caption">Параметри</div>
      <v-btn small icon @click="$emit('collapse')">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider class="mb-2 mt-1" />
    <v-simple-table dense>
      <tbody>
        <tr>
          <td> Висота </td>
          <td>
            <v-text-field v-model="value.height"  style="width: 25px !important" class="caption" hide-details dense  ></v-text-field>
          </td>
        </tr>
        <tr>
          <td> Ширина </td>
          <td>
            <v-text-field v-model="value.width"  style="width: 25px !important" class="caption" hide-details dense   ></v-text-field>
          </td>
        </tr>
        <tr>
          <td> Закруглення </td>
          <td>
            <v-text-field v-model="value.radius"  style="width: 25px !important" class="caption" hide-details dense  ></v-text-field>
          </td>
        </tr>
        <tr>
          <td> Розміщення </td>
          <td>
            <v-select v-model="value.position"
                      :items="position"
                      style="width: 55px !important"
                      class="caption" hide-details dense ></v-select>
          </td>
        </tr>



      </tbody>
    </v-simple-table>


  </v-sheet>
</template>

<script>
export default {
  name: "ftDisplayEdit",
  props : ['value'],
  data() {
    return {
      position : [
        { text : 'По центру' , value : 'center' },
        { text : 'Верх' , value : 'top' },
        { text : 'Низ' , value : 'bottom' }
      ]
    }
  },
  watch : {
    value : {
      handler(val) {
        this.$emit('input', val)
      },
      deep: true
    }
  },
  methods : {
    hover(val) {
      this.$emit('hover',val)
    },
  },
  mounted() {
    if(this.value) {

      if ( this.value.includes('sm') ) { this.sm = true }
      if ( this.value.includes('md') ) { this.md = true }
      if ( this.value.includes('lg') ) { this.lg = true }


    }
  }
}
</script>

<style scoped>

</style>