<template>
  <div>

    <div class="d-flex" >
      <img class="rounded mr-3" height="40" alt="WeStudy" src="@/assets/img/download_apple.png"  >
      <img class="rounded" height="40" alt="WeStudy" src="@/assets/img/download_google.png"  >
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "avalonAppsElement",
  props : ['value','editMode'],
  computed : {
    ...mapState('templates',  [ 'news' , 'editorMode', 'templateVars_init', 'templateVars_current' ]),
    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    }
  },
  methods : {
    parametr(varName) {
      let param = '';
      if (  this.editorMode ) { param = this.templateVars_current[varName].value }
      if ( !this.editorMode ) { param = this.templateVars_init[varName]    }
      return param
    },
  }
}
</script>

<style scoped>

</style>