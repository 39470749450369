<template>
<div class="d-flex align-center" :class="TEMPLATES_flexAlignment(params.align)">



      <div v-for="(button,i) in value" :key="i + block + row + item">
        <div v-if="button.params.route">

          <router-link :to="businessPublicLink('page/' + button.params.route)"
                       style="text-decoration: none !important"  >
            <v-btn
                :color="button.params.css.color"
                :text="button.params.css.text"
                :dark="button.params.css.dark"
                :light="!button.params.dark"
                :x-small="button.params.css.size === 'x-small' "
                :small="button.params.css.size === 'small' "
                :large="button.params.css.size === 'large' "
                :x-large="button.params.css.size === 'x-large' "
                class=" px-6 "
                elevation="0"
                rounded
                :class="` ${i < value.length ? 'mr-3' : '' }
                    font-weight-${button.params.css.fontWeight}
                    ${!button.params.css.caps ? 'noCaps' : ''}` " >

              {{  button.text }}

            </v-btn>
          </router-link>
        </div>
        <v-btn v-else
            @click="buttonAction(button)"
            :color="button.params.css.color"
            :text="button.params.css.text"
            :dark="button.params.css.dark"
            :light="!button.params.dark"
            :x-small="button.params.css.size === 'x-small' "
            :small="button.params.css.size === 'small' "
            :large="button.params.css.size === 'large' "
            :x-large="button.params.css.size === 'x-large' "
            class=" px-6 "
            elevation="0"
            rounded
            :class="` ${i < value.length ? 'mr-3' : '' }
                    font-weight-${button.params.css.fontWeight}
                    ${!button.params.css.caps ? 'noCaps' : ''}` " >

          {{  button.text }}

        </v-btn>
      </div>


</div>
</template>

<script>
import {mapActions, mapState} from "vuex";
export default {
    name: "avalon-button-element",
    props : ['value','params','block','row','item'],
    data() {
      return {
      }
    },

  computed : {
    ...mapState('templates',  ['editorMode', 'products' ,  'templateVars_init', 'templateVars_current' ]),
    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    },
  },
  methods : {

    ...mapActions('cart', [ 'ADD_CART_ITEM','REMOVE_CART_ITEM']),

    buttonAction(button) {
      if ( this.editorMode ) { return }
      switch ( button.action ) {
        case 'gotoPage'      : this.gotoPage(button.params); break;
        case 'gotoLink'      : this.gotoLink(button.params); break;
        case 'openCart'      : this.openCart(); break;
        case 'orderProducts' : this.orderProducts(button.params); break;
        case 'addToCart'     : this.addToCart(button.params); break;
      }
    },
    gotoPage( value ) {

      if (value.route ) {
        let page =  value.route.split('/')
        if ( page.length > 0) {
          page = page[page.length-1]
          this.$store.state.templates.selectedPage = page
          this.$router.push(value.route)
        }
      }


    },
    gotoLink( value ) {
      window.open(value.link)
    },
    openCart() {
    //  this.$store.state.cart.showCart = true
      this.$store.state.cart.showCheckout = true
    },
    orderProducts( value ) {
      this.$store.state.cart.cart = {};
      this.$store.state.cart.quantity = 0;
      this.addToCart( value )
      this.$store.state.cart.showCheckout = true
    },
    addToCart( value ) {

      value.products.forEach((id) => {
       let product =  this.products.find(el=>el.id === id)
        this.ADD_CART_ITEM(product)
      })

    },

    //technical
  }







}
</script>

<style scoped>

</style>