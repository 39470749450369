<template>
  <div>

      <v-sheet color="transparent"  >
        <h2 :style="accentColor">НОВИНИ </h2>

        <div v-for="(item,i) in news" :key="i" class="mt-3">
          <span class="caption">{{  item.date  }}</span>
          <v-divider />
          <router-link :to="`/${businessAlias}/news/${item.alias}`"
                       style="color:white; text-decoration: none">
            <h4>{{ item.title }}</h4>
          </router-link>
        </div>
      </v-sheet>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "avalonNewsElement",
  props : ['value','editMode'],
  computed : {
    ...mapState('templates',  [ 'news' , 'editorMode', 'templateVars_init', 'templateVars_current' ]),
    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    }
  },
  methods : {
    parametr(varName) {
      let param = '';
      if (  this.editorMode ) { param = this.templateVars_current[varName].value }
      if ( !this.editorMode ) { param = this.templateVars_init[varName]    }
      return param
    },
  }
}
</script>

<style scoped>

</style>