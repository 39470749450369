<template>
<div>

  <div v-for="(item,key) in value.fields" :key="key">

    <h5 v-if="item && value.displayTitle"
        :style="titleStyle"
        class="font-weight-medium mt-3">
      {{ $t('lead_' + key) }}:
    </h5>
    <v-text-field  v-if="item && ['name','email'].includes(key) "
                   v-model="entityData[key]"
                   :placeholder="$t('lead_' + key)"
                   style="color: red !important"
                   :background-color="value.backgroundColor"
                   :dark="value.darkMode"
                   hide-details
                   dense
                   outlined
                   :disabled="EDITOR_MODE" />

    <ws-phone-input  v-if="item && key === 'phone' "
                     v-model="entityData[key]"
                     :background-color="value.backgroundColor"
                     hide-details
                     :dark="value.darkMode" />

    <v-textarea   v-if="item && key === 'comment' "
                   v-model="entityData[key]"
                   :placeholder="$t('lead_' + key)"
                   style="color: red !important"
                   :background-color="value.backgroundColor"
                   :dark="value.darkMode"
                   hide-details
                   dense
                   outlined
                   :disabled="EDITOR_MODE" />

  </div>

  <v-btn @click="addLead"
         :rounded="value.buttonRounded"
         :color="value.buttonBackgroundColor"
         :text="value.buttonBackgroundStyle"
         elevation="0"
         block
         class="noCaps mt-3">
    <span :style="`color : ${value.buttonTextColor}`">{{  value.buttonText  }}</span>
  </v-btn>

</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "avalonDeliveryFieldElement",
  props : {
    value : {
      type : Object ,
      default() {
        return {
          fields : {}
        }
      }
    },
    params : {
      type : Object ,
      default() {
        return {
          margin : {}
        }
      }
    }
  },
  data() {
    return {
      entityData : {}
    }
  },
  computed : {
    titleStyle() {
      let style = '';

      if ( this.value.titleColor ) {
        style += `color : ${this.value.titleColor}`
      }

      return style

    }
  },
  methods : {
    ...mapActions('cart', [ 'ADD_CART_ITEM','REMOVE_CART_ITEM']),
    ...mapActions('leads', ['ADD_BUSINESS_LEAD']),

    async addLead() {
      if ( this.EDITOR_MODE ) { return }

      if ( !this.entityData.phone ) {
        return this.notify(this.$t('PhoneNumberEmpty'))
      }

      this.entityData.origin = 'contact_form'
      this.entityData.device = this.DEVICE
      let result = await this.ADD_BUSINESS_LEAD(this.entityData)
      if( !result ) { return }
      this.notify(this.$t('BusinessLeadCreated'))
      this.entityData = {}
    },

    //technical
  },
  mounted() {

  }







}
</script>

<style scoped>

</style>