<template>
  <div>

      <v-sheet color="transparent"  >
        <div v-if="value.header.active"
             class="avalonFont mb-3"
             :class="`font-weight-${params.header.text.fontWeight}
                    text-${params.header.text.headingSize}
                    text-${params.header.text.align}  `"
             :style="`color : ${params.header.text.fontWeight};
                    line-height : ${params.header.text.lineHeight};
                    font-size : ${params.header.text.fontSize}px; `" >
          {{ value.header.text }}
        </div>
        <div v-if="value.telegram.active"
            class="d-flex">
          <v-btn text class="noCaps" :href="!editorMode ? value.telegram.link : null" target="_blank">
            <v-img width="23"  class="rounded mr-3"  alt="WeStudy" src="@/assets/socials/telegram.png"  />

            Telegram
          </v-btn>
        </div>
        <div v-if="value.instagram.active"
             class="d-flex ">
          <v-btn text class="noCaps" :href="!editorMode ? value.instagram.link : null" target="_blank">
            <v-icon class="mr-2">mdi-instagram</v-icon>
            Instagram
          </v-btn>
        </div>
        <div v-if="value.facebook.active"
             class="d-flex ">
          <v-btn text class="noCaps" :href="!editorMode ? value.facebook.link : null" target="_blank">
            <v-icon class="mr-2">mdi-facebook</v-icon>
            Facebook
          </v-btn>
        </div>
      </v-sheet>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "avalonNewsElement",
  props : ['value','editMode','params'],
  computed : {
    ...mapState('templates',  [ 'editorMode', 'templateVars_init', 'templateVars_current' ]),
    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    }
  },
  methods : {
    parametr(varName) {
      let param = '';
      if (  this.editorMode ) { param = this.templateVars_current[varName].value }
      if ( !this.editorMode ) { param = this.templateVars_init[varName]    }
      return param
    },
  }
}
</script>

<style scoped>

</style>