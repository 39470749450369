<template>

    <v-sheet  :color="block.value.background_color" :dark="block.value.dark" :min-height="!block.value.noPadding ? 300 : null"
              :style="backgroundImage" >

      <v-sheet :color="block.value.overlay_color"
               style="height : 100% !important"
               :class="blockTopPaddingHeaderHandle" >
        <div class="section-content fill-height" :class="block.value.noPadding ? '' : 'py-16'">
          <!-- Slot for edit block menu-->
          <slot name="edit"> </slot>
          <div>
            <h1 style="line-height: 1.2"  class="font-weight-light text-center">{{ block.value.title }}</h1>
            <h3 style="line-height: 1.2"  class="font-weight-light  text-center">{{ block.value.subtitle }}</h3>
          </div>
          <v-row class="px-6 mx-0" >
            <v-col v-for="(row,i) in block.value.items" :key="i"
                   :cols="block.value.rows_dimensions.sm[i]"
                   :xs="block.value.rows_dimensions.sm[i]"
                   :sm="block.value.rows_dimensions.sm[i]"
                   :md="block.value.rows_dimensions.md[i]"
                   :lg="block.value.rows_dimensions.lg[i]"
                   :xl="block.value.rows_dimensions.lg[i]"
                   class="d-flex"
                   :class="[ block.value.vertical_align[i] ,  block.value.noPadding ? '' : 'mt-12']"
                   >

              <v-sheet color="transparent" style="width : 100% !important">

                <div v-if="editorMode" class="d-flex justify-center">
                  <v-btn-toggle  dark :background-color="wsDARKER"   class="text-center" v-model="$store.state.templates.page.blocks[index].value.vertical_align[i]">
                    <v-btn small :color="wsDARKER" value=""><v-icon color="white" small>mdi-format-align-top</v-icon></v-btn>
                    <v-btn small :color="wsDARKER" value="align-center"><v-icon small>mdi-format-align-middle</v-icon></v-btn>
                    <v-btn small :color="wsDARKER" value="align-end"><v-icon small>mdi-format-align-bottom</v-icon></v-btn>

                  </v-btn-toggle>
                  <v-btn @click="editColumn(i)" small dark class="ml-3" :color="wsDARKER" ><v-icon small>mdi-pencil</v-icon></v-btn>
                </div>


                <v-sheet :style="getColumnStyle(i)"
                         :elevation="COLUMN_PARAM()('elevation',index,i)"
                         color="transparent">
                    <v-sheet :style="getColumnOverlayStyle(i)" color="transparent">
                      <draggable  v-model="$store.state.templates.page.blocks[index].value.items[i]"
                                  group="rows"
                                  handle=".elementDraggable"
                                  :forceFallback="true"
                                  :empty-insert-threshold="100"
                                  tag="div" >

                        <div v-for="(item,j) in row" :key="j" >

                          <div class="d-flex align-baseline">

                            <v-icon v-if="editorMode" style="cursor: grab;" class="elementDraggable noSelect" size="28"  >
                              mdi-drag
                            </v-icon>

                            <v-fade-transition>
                              <component v-if="item.params.display.includes(windowSize) || editorMode "
                                         :is="item.type"
                                         style="width : 100% !important"
                                         :value="item.value"
                                         :params="item.params"
                                         :subElement="item.subElement"
                                         :block="index"
                                         :row="i"
                                         :item="j"
                                         :class="getMargin(item.params.margin)"
                              />
                            </v-fade-transition>

                          </div>


                        </div>
                      </draggable>
                    </v-sheet>
                </v-sheet>

                <ftContainerElementsMenu :block="index" :row="i" :templateElements="templateElements" />
              </v-sheet>





            </v-col>

          </v-row>


        </div>
      </v-sheet>

    </v-sheet>


</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import ftText from "@/components/templates/editor/elements/ftText";
import ftImg from "@/components/templates/editor/elements/ftImg";

import ftTemplateElement from "@/components/templates/editor/elements/ftTemplateElement";

import ftContainerElementsMenu from "@/components/templates/editor/ftContainerElementsMenu";
import draggable from "vuedraggable";

export default {
  name: "avalonBlockContainer",
  props : ['block','index'],
  components : {
    draggable,
    ftText,
    ftImg,
    ftTemplateElement,
    ftContainerElementsMenu,

  },
  data() {
    return {
      selectedCategory : '',

      templateElements : [
        { text: 'AvalonQuoting' , element: 'ftText'  , icon: 'mdi-format-header-1', params: {type: 'heading'}},
      ]

    }
  },
  computed : {
    ...mapState('templates',  [  'editorMode', 'products', 'selectedBlock' , 'templateVars_init', 'templateVars_current'  ]),
    ...mapState('cart',  [  'cart'  ]),
    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    },
    //this is temporary ( 19/09/2021 - 14:59 ) until HEADER BLOCKS will be written in database
    blockTopPaddingHeaderHandle() {
     if ( this.index === 0 && this.parametr('header_template') === 'avalonHeader_var_2' ) {
       return 'pt-16'
     }
     return ''
    },

    backgroundImage() {
      if ( this.block.value.background_type === 'img' && this.block.value.background_img) {
        return  `background: url('${this.block.value.background_img}');
                 background-size : cover;
                 position:relative`
        //  background-attachment: fixed ;
      } else {
        return ''
      }

    }
  },
  methods : {

    ...mapActions('cart', [ 'ADD_CART_ITEM','REMOVE_CART_ITEM']),
    ...mapMutations('templates', [ 'DISPLAY_EDIT_COLUMN']),
    ...mapGetters('templates', ['COLUMN_PARAM']),

    getColumnStyle(columnIndex) {
      let style = '';
      if (this.COLUMN_PARAM()('background_type',this.index,columnIndex) === 'color' ) {
        if (this.COLUMN_PARAM()('background_color',this.index,columnIndex) ) {
          style += `background: ${this.COLUMN_PARAM()('background_color',this.index,columnIndex)} !important;`
        }
      }

      if (this.COLUMN_PARAM()('background_type',this.index,columnIndex) === 'img' ) {
        if (this.COLUMN_PARAM()('background_img',this.index,columnIndex) ) {
          style += `background: url('${this.COLUMN_PARAM()('background_img',this.index,columnIndex)}') ;`
        }
      }

      if (this.COLUMN_PARAM()('rounded',this.index,columnIndex) ) {
        style += `border-radius: 50%;`
      } else  if (this.COLUMN_PARAM()('border_radius',this.index,columnIndex) ) {
        style += `border-radius: ${this.COLUMN_PARAM()('border_radius',this.index,columnIndex)}px ;`
      }

      if (this.COLUMN_PARAM()('border',this.index,columnIndex) ) {
        let color = ''
        if (this.COLUMN_PARAM()('border_color',this.index,columnIndex) ) {
          color = this.COLUMN_PARAM()('border_color',this.index,columnIndex)
        }
        style += `border: ${this.COLUMN_PARAM()('border',this.index,columnIndex)}px solid ${color} !important;`
      }

      return style
    },
    getColumnOverlayStyle(columnIndex) {

      let style = ""

      if (this.COLUMN_PARAM()('background_type',this.index,columnIndex) === 'img' ) {
        if (this.COLUMN_PARAM()('overlay_color',this.index,columnIndex) ) {
          style += `background: ${this.COLUMN_PARAM()('overlay_color',this.index,columnIndex)} !important;`
        }
      }

      if (this.COLUMN_PARAM()('rounded',this.index,columnIndex) ) {
        style += `border-radius: 50%;`
      } else  if (this.COLUMN_PARAM()('border_radius',this.index,columnIndex) ) {
        style += `border-radius: ${this.COLUMN_PARAM()('border_radius',this.index,columnIndex)}px ;`
      }

      if (this.COLUMN_PARAM()('padding_top',this.index,columnIndex) ) {
        style += `padding-top: ${this.COLUMN_PARAM()('padding_top',this.index,columnIndex)}px ;`
      }
      if (this.COLUMN_PARAM()('padding_bottom',this.index,columnIndex) ) {
        style += `padding-bottom: ${this.COLUMN_PARAM()('padding_bottom',this.index,columnIndex)}px ;`
      }
      if (this.COLUMN_PARAM()('padding_left',this.index,columnIndex) ) {
        style += `padding-left: ${this.COLUMN_PARAM()('padding_left',this.index,columnIndex)}px ;`
      }
      if (this.COLUMN_PARAM()('padding_right',this.index,columnIndex) ) {
        style += `padding-right: ${this.COLUMN_PARAM()('padding_right',this.index,columnIndex)}px ;`
      }


      return style
    },

    editColumn(columnIndex) {

      this.$store.state.templates.selectedBlock = this.index
      this.$store.state.templates.selectedBlockColumn = columnIndex
      this.DISPLAY_EDIT_COLUMN()
    },

    parametr(varName) {
      let param = '';
      if (  this.editorMode ) { param = this.templateVars_current[varName].value }
      if ( !this.editorMode ) { param = this.templateVars_init[varName]    }
      return param
    },

    //technical
    getMargin(margin) {
      let marginCLass = ''

      if ( margin.mb !== 0 ) {
        marginCLass += + margin.mb > 0 ? ('mb-' + margin.mb) : 'mb-n' + margin.mb*(-1)
        marginCLass += ' '
      }
      if ( margin.mt !== 0 ) {
        marginCLass += + margin.mt > 0 ? ('mt-' + margin.mt) : 'mt-n' + margin.mt*(-1)
        marginCLass += ' '
      }
      if ( margin.ml !== 0 ) {
        marginCLass += + margin.ml > 0 ? ('ml-' + margin.ml) : 'ml-n' + margin.ml*(-1)
        marginCLass += ' '
      }
      if ( margin.mr !== 0 ) {
        marginCLass += + margin.mr > 0 ? ('mr-' + margin.mr) : 'mr-n' + margin.mr*(-1)
        marginCLass += ' '
      }
        return marginCLass
    }


  },
}
</script>

<style scoped>
.headerMenuItem {
  font-size: 0.9rem !important;
  transition: all 0.2s ease;
  text-decoration: none;
  color : #f1f1f1;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
</style>