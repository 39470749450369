<template>
  <v-sheet  dark class="pa-4"
            @mouseenter="hover(true)"
            @mouseleave="hover(false)" >



    <div class="d-flex justify-space-between align-center ">
     <div class="caption">Параметри</div>
      <v-btn small icon @click="$emit('collapse')">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider class="mb-2 mt-1" />
    <v-simple-table dense>
      <tbody>
        <tr>
          <td> Комп'ютер  </td>
          <td>
            <v-checkbox hide-details v-model="lg" dense ></v-checkbox>
          </td>
        </tr>
        <tr>
          <td>Планшет  </td>
          <td> <v-checkbox hide-details v-model="md" dense ></v-checkbox> </td>
        </tr>
        <tr>
          <td> Телефон  </td>
          <td> <v-checkbox hide-details v-model="sm" dense ></v-checkbox> </td>
        </tr>
      </tbody>
    </v-simple-table>


  </v-sheet>
</template>

<script>
export default {
  name: "ftDisplayEdit",
  props : ['value'],
  data() {
    return {
      sm : false,
      md : false,
      lg : false,
    }
  },
  watch : {
    sm(val) {
      if ( !val ) {

        if ( this.value.includes('sm') ) { this.value = this.value.filter(el=>el !== 'sm') }
      } else {
        if ( !this.value.includes('sm') ) { this.value.push('sm') }
      }
    },
    md(val) {
      if ( !val ) {
        if ( this.value.includes('md') ) { this.value = this.value.filter(el=>el !== 'md') }
      } else {
        if ( !this.value.includes('md') ) { this.value.push('md') }
      }
    },
    lg(val) {
      if ( !val ) {
        if ( this.value.includes('lg') ) { this.value = this.value.filter(el=>el !== 'lg') }
      } else {
        if ( !this.value.includes('lg') ) { this.value.push('lg') }
      }
    },
    value(val) {
      this.$emit('input',val)
    }
  },
  methods : {
    hover(val) {
      this.$emit('hover',val)
    },
  },
  mounted() {
    if(this.value) {

      if ( this.value.includes('sm') ) { this.sm = true }
      if ( this.value.includes('md') ) { this.md = true }
      if ( this.value.includes('lg') ) { this.lg = true }


    }
  }
}
</script>

<style scoped>

</style>